import React, { Component } from 'react';

import MainTemplate from 'components/04_Templates/MainTemplate'

import SearchForm from 'components/02_Molecules/Forms/SearchForm'
import ThreadListBlock from 'components/03_Organisms/ThreadListBlock'
import { ThreadFormWithModalAndButton } from 'components/02_Molecules/Forms/ThreadForm';
import { getThreads } from 'actions/fetch/thread';

class App extends Component {
    constructor() {
        super()
        this.state = {
            isLoading: true
        };
    }

    async componentDidMount() {
        const { route } = this.props.match.params
        await getThreads(route)
    }

    render() {
        const { route } = this.props.match.params

        const MainPageBlock =(props) => {
            const {className, style} = props
            return <div className={className} style={style}>
                <ThreadFormWithModalAndButton route={route} className="mb-4 pb-4 border-bottom" />
                <SearchForm className="mb-4" mode="THREAD" />
                <ThreadListBlock />
            </div>
        }

        return <MainTemplate
            BreadcrumbRoutes={[
                {title: route}
            ]}
            MainPageBlock={MainPageBlock}
        />

    }

}


export default App