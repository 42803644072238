import {sort_array} from "actions/fetch/fetch" 

export const sanitize = {
    encode: function (str) {
        return str.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#39;');
    },

    decode: function (str) {
        return str.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&quot;/g, '"').replace(/&#39;/g, '\'').replace(/&amp;/g, '&');
    }
};

export const parse_keywords = (keyword) => {
    var kws = keyword.split(" ")
    var result = []
    
    kws.forEach(kw=>{
        result = result.concat(kw.split("　"))
    })
    
    return result
}


export function omittedContent(string, max_length = 140) {
    if (!string) {
        string = ""
    }

    if (string.length > max_length) {
        return string.substr(0, max_length) + '...';
    }
    return string;
}

export function _search(string, keyword){
    var kws = parse_keywords(keyword)
    kws = kws.map(kw => {
        return {
            keyword: kw,
            index: string.indexOf(kw)
        }
    })

    kws = sort_array(kws, "index", true)

    return {
        result: kws.filter(item => item.index !== -1),
        isMatched: function(){
            for(var i=0; i < kws.length; ++i ){
                var kw = kws[i]
                if (kw.index === -1){
                    return false
                }
            }
            return true
        },
        indexes: function(max_length){
            var result=[[
                Math.max(Math.floor(kws[0].index - max_length/2), 0),
                Math.min(Math.floor(kws[0].index + max_length/2), string.length) 
            ]]

            for(var i=0; i < kws.length; ++i ){
                var kw = kws[i]
                var start = Math.floor(kw.index - max_length/2)
                var end = Math.floor(kw.index + max_length/2)

                if (result[result.length-1][1] < start ){
                    result.push([ start, Math.min(end, string.length) ])
                } else {
                    result[result.length-1][1] = Math.min(end, string.length)
                }
            }
            return result
        }
    }
}

export function search(string, keyword, max_length = 300) {
    var data = _search(string, keyword)
    var isMatched = data.isMatched()
    var kws = data.result
    var indexes = data.indexes(max_length)

    data = indexes.map(index=>{
        return string.substring(index[0], index[1])
    })

    data = data.join(" ... ")

    if (indexes[0][0] !== 0){ 
        data = "... " + data
    }

    if (indexes[indexes.length-1][1] !== string.length){
        data = data + " ..."
    }

    for(var i=0; i < kws.length; ++i ){
        var kw = kws[i].keyword
        data = data.replace(kw, `<span class="bg-warning">${kw}</span>`)
    }

    return {
        result: isMatched,
        value: data
    }
}