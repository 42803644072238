import React, { Component } from 'react';

import { iso2localatring } from 'actions/time'

class App extends Component{
    constructor() {
        super()
        this.state = {
        }
    }

    render() {
        const { item, onClick, className, style } = this.props

        return <div className={className} style={style} onClick={onClick}>
            <div className="d-flex flex-wrap align-items-end">
                <span className="font-weight-bold">{item.user_name}</span>
                {Boolean(item.nRes) && <span className="ml-2 text-black-50">返信: {item.nRes}件</span>}
                <span className="ml-2 text-black-50">{iso2localatring(item.updated_at)}</span> 
                {item.isEdited && <span className="ml-2">(編集済み)</span>}
            </div>
        </div>
        
    }
}

export default App