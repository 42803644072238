import React, { Component } from 'react';
import { connect } from 'react-redux';

import LogItem from 'components/02_Molecules/LogItem'
import Loading from "components/01_Atoms/parts/Loading"

class App extends Component {

    constructor() {
        super()
        this.state = { };
    }

    render() {
        const { className, style, logs, isLoading } = this.props;

        return <div className={className} style={style}>
            <div className="text-left">
                {!isLoading && logs.map(item => <LogItem className="mt-3" item={item} isClickable={true} key={item.log_id}/>)}
                {isLoading && <Loading />}
            </div>
        </div>
    }

}

const mapStateToProps = state => {
    return {
        logs: state.bbslogs.data.filter(log => !log.response_to && !log.disabled),
        isLoading: state.bbslogs.isLoading,
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
