import { Formik } from "formik"
import React, { Component } from 'react';

import {
    Button,
    Form as FormArea,
} from 'reactstrap';

import { connect } from 'react-redux';

import OpenButton from 'components/01_Atoms/parts/OpenButton'
import { FormModal } from 'components/01_Atoms/layouts/Modal'
import { FormInput, MarkDownInput } from 'components/01_Atoms/parts/Inputs'
import { NeedLogin } from 'components/01_Atoms/bbs_items/NeedLogin'
import { LogBodyBase } from 'components/01_Atoms/bbs_items/LogBody'

import { logValidationSchema } from "actions/form/validationSchema"
import { addLogInfo, deleteLogInfo} from 'redux/actions';
import { getLogs, postLog, postResponse, editLog } from 'actions/fetch/log';


import {
    isLoggedIn, 
} from 'actions/auth' 


class App extends Component {
    constructor() {
        super()
        this.state = {
            validationError: "",
        };
    }

    render() {
        const { className, style, user_info, thread_info, onSubmit, item, mode } = this.props;
        const { validationError } = this.state

        const { thread_id } = thread_info.data        

        const onLogSubmit = async (values, { resetForm }) => {
            let body = {
                submition: {
                    user_name: user_info.name,
                    user_id: user_info.sub,
                    content: values.content,                
                }
            }

            let res = {
                result: null
            }

            if (mode==="RESPONSE_LOG"){

                console.log("---response---")
                res = await postResponse(body, thread_id, item.log_id)

            } else if (mode==="EDIT"){

                console.log("---edit---")
                body.submition = { content: values.content }
                res = await editLog(body, thread_id, item.log_id)

            } else {

                console.log("---post---")
                res = await postLog(body, thread_id)

            }


            if (res.result) {
                await getLogs(thread_id);
                resetForm()
                onSubmit()
            }
            this.setState({
                validationError: res.ErrorMessage
            })
        }
        
        const initialValues = {
            content: mode==="EDIT"? item.content : ''
        }

        const renderForm = ({handleSubmit, isSubmitting}) => (
            <FormArea className="text-left" onSubmit={handleSubmit}>
                <FormInput className="mb-2" type="textarea" name="content" placeholder="Content" />
                <Button color="primary" type="submit" disabled={isSubmitting}>Submit</Button>{' '}
            </FormArea>
        )

        const LogForm = () => {
            return <div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={logValidationSchema}
                    onSubmit={onLogSubmit}
                    render={renderForm}
                />
                <div className="form-error" >{validationError}</div>
            </div>
        }

        return <div className={className} style={style}>
            {(mode==="RESPONSE_LOG") && <LogBodyBase item={item} className={"border-top border-bottom p-3 mb-3"} />}
            {isLoggedIn()? <LogForm /> : <NeedLogin />}
        </div>
    }
}

const mapStateToProps = state => {
    return {
        user_info: state.user_info,
        thread_info: state.thread_info,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onAddLogInfo(log_info) {
            dispatch(addLogInfo(log_info))
        },
        onDeleteLogInfo(){
            dispatch(deleteLogInfo())
        }
    }
};

const LogForm = connect(mapStateToProps, mapDispatchToProps)(App)
export default LogForm

export const LogFormWithModal = (props) => {
    let { className, style, children, title, ...FormAttrs } = props

    return <FormModal 
            title={title}
            className={className} style={style} 
            Form={LogForm}
            FormAttrs={FormAttrs}
        >
        {children}
    </FormModal>
}

export const LogFormWithModalAndButton = (props) => {
    let { title } = props

    title = title || "このスレッドに返信する"

    return <LogFormWithModal {...props} >
        <OpenButton info={title} />
    </LogFormWithModal>
}