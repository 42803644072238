import React, { Component } from 'react';
import { connect } from 'react-redux';

import {isLoggedIn} from 'actions/auth'
import MainTemplate from 'components/04_Templates/MainTemplate'


class App extends Component {
    constructor(props) {
        super()
        this.state = {
            input: "",
            message: ""
        };
    }

    async getMessage (path) {
    
        const {user_info} = this.props; 
        const res = await fetch(process.env.REACT_APP_API_URL + path, { 
            mode: 'cors',
            headers: {
                'x-api-key' : process.env.REACT_APP_API_KEY,
                'Authorization': user_info.idToken
            }
        })
        const data = await res.json()
        
        this.setState({
            message: data.message
        })
    }

    async componentDidMount() {
        const {user_info} = this.props; 

        if(isLoggedIn()){
            this.setState({
                message: "ユーザー: " + user_info.name + " (" + user_info.sub + ")"
            })
        }else{
            this.setState({
                message: "ログインしてください"
            })
        }
    }

    render() {
        const { message } = this.state

        const IndexItem = (props) => {
            const { className, style, href, title } = props
            return <div className={className} style={style}>
                <div className="index-list">
                    <div className="index-link">{title}</div>
                    <a href={href}>
                        <div display="None"></div>
                    </a>
                </div>
            </div>
        }

        const MainPageBlock =(props) => {
            const {className, style} = props
            return <div className={className} style={style}>
                <div className="index text-center">
                    <h1 className="index-head">AlgodooOnline</h1>
                    <div className="message">{message}</div>
                    <div className="index-nav">
                        <IndexItem href="/i/BBS" title="BBS掲示板" />
                        <IndexItem href="/i/WIKI" title="Wiki" />
                        <IndexItem href="/i/IMAGE" title="画像掲示板" />
                        <IndexItem href="/i/DATA" title="Phz/Phnデータ掲示板" />
                    </div>
                </div>
            </div>
        }

        return <MainTemplate
            MainPageBlock={MainPageBlock}
        />
    }
}

const mapStateToProps = state => {
    return {
        user_info: state.user_info,
    }
};

export default connect(mapStateToProps)(App);
