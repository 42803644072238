import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import AuthTemplate from 'components/04_Templates/AuthTemplate'
import ChangePassForm from 'components/02_Molecules/AuthForms/ChangePassForm'


class App extends Component {

    constructor() {
        super()
        this.state = {
            isPasswordChanged: false,
        };
    }

    render() {
        const { isPasswordChanged } = this.state

        const handleChangePass = ()=>{
            this.setState({
                isPasswordChanged: true
            })
        }

        const MainPageBlock = () => <ChangePassForm 
            onPasswordChanged={handleChangePass}
        />

        return isPasswordChanged? <Redirect to="/" /> : <AuthTemplate
            MainPageBlock={MainPageBlock}
        />
    }
}

export default App