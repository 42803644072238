import React, { Component } from 'react';
import { Formik } from "formik"

import { connect } from 'react-redux';
import { Button, Form as FormArea } from 'reactstrap'

import { InputWithLabel } from 'components/01_Atoms/parts/Inputs'
import { forgotPassword } from 'actions/auth' 
import { fotgotPassValidationSchema } from 'actions/form/validationSchema'


class App extends Component {
    constructor() {
        super()
        this.state = {
            validationError: "",
        };
    }

    render() {
        const { onCodeDelivered, ...props } = this.props
        const { validationError} = this.state

        const initialValues = {
            name:""
        }

        const validationSchema=fotgotPassValidationSchema

        const onSubmit = async (values) => {
            const { name } = values;
            forgotPassword(name, (err) => {
                if (err){
                    this.setState({validationError_code: err.message})
                } else {
                    onCodeDelivered(name)
                }
            })
        }

        const renderForm = ({handleSubmit, isSubmitting}) => {
            return <FormArea className="text-left" onSubmit={handleSubmit}>
                <h3 className="border-bottom mb-4 font-weight-bolder">パスワードを忘れた場合</h3>

                <InputWithLabel title="ユーザー名" className="mb-2" name="name" />
                <Button color="primary" type="submit" disabled={isSubmitting}>検証コードを送る</Button>

                <div className="form-error" >{validationError}</div>
            </FormArea>
        }

        return <div {...props}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                render={renderForm}
            />
        </div>
    }
}

export default App