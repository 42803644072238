import React, { Component } from 'react';

import MainTemplate from 'components/04_Templates/MainTemplate'

export default class App extends Component {
    constructor(){
        super()
        this.state = {}
    }
    render(){
        const { MainPageBlock, BreadcrumbRoutes } = this.props

        const MainAuthBlock =()=> <div className="col-md-4 offset-md-4 bg-light mt-4 p-4 border">
            <MainPageBlock />
        </div>

        return <MainTemplate
            BreadcrumbRoutes={BreadcrumbRoutes}
            MainPageBlock={MainAuthBlock}
        />
    
    }
}