import React, { Component } from 'react'

import {
    Breadcrumb, BreadcrumbItem
} from "reactstrap"

class App extends Component {
    constructor() {
        super()
        this.state = {
        };
    }

    render() {
        const { routes } = this.props
        
        const InactiveItem = (props) => {
            const { route } = props
            return <BreadcrumbItem><a href={route.url}>{route.title}</a></BreadcrumbItem>
        }

        const ActimeItem = (props) => {
            const { route } = props
            return <BreadcrumbItem active>{route.title}</BreadcrumbItem>
        }
        
        return <Breadcrumb color="primary">
            <BreadcrumbItem><a href="/" >ホーム</a></BreadcrumbItem>
            {routes.map((route) => {
                return route.url ? <InactiveItem route={route} /> : <ActimeItem route={route} />}
            )}
        </Breadcrumb>
    }
}

export default App