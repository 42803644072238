import { 
    getNewThreads, batchAddLikesThread,
    changeIsLoadingThreads
} from 'redux/actions';
import store from 'redux/store';
import { isLoggedIn } from 'actions/auth';

import { search, sanitize } from 'actions/search'
import { get, post, sort, put, del } from 'actions/fetch/fetch'

const {dispatch} = store


export async function search_threads(keyword, param) {
    dispatch(changeIsLoadingThreads(true))
    var { threads } = store.getState().bbsthreads

    threads = threads.map(item => {
        item.content_updated = null
        item.title_updated = null
        
        if (item.isDeleted){
            item.disabled = true
            return item
        }

        const searchContent = search(sanitize.encode(item.content), keyword)
        const searchTitle = search(sanitize.encode(item.title), keyword)
        const searchAll = search(sanitize.encode(item.title + item.content), keyword)

        if (searchAll.result){
            item.disabled = false
            item.content_updated = searchContent.value
            item.title_updated = searchTitle.value
        } else {
            item.disabled = true
            return item
        }

        return item
    })

    threads = await sort(threads, param)

    dispatch(getNewThreads(threads))
    dispatch(changeIsLoadingThreads(false))
}

export async function sort_threads(param){
    dispatch(changeIsLoadingThreads(true))
    var { threads } = store.getState().bbsthreads

    threads = await sort(threads, param)

    dispatch(getNewThreads(threads))
    dispatch(changeIsLoadingThreads(false))
}

export async function getThreads(route="BBS") {
    dispatch(changeIsLoadingThreads(true))

    let data =  await get("/threads/"+route)
    data = await sort(data.submitions, "NEWEST_UPDATED")
    
    if (isLoggedIn()){
        let post_ids = data.filter(item => item.nLike > 0).map(item => item.thread_id)
        let likes = await post("/like/own/batch", {post_ids})
        dispatch(batchAddLikesThread(likes.likes))
    }

    dispatch(getNewThreads(data))
    dispatch(changeIsLoadingThreads(false))
}

export async function postThread(body, route="BBS") {
    return await post("/threads/"+route, body)
}

export async function editThread(body, route, thread_id){
    return await put("/threads/"+ route + '/' + thread_id, body)
}

export async function deleteThread(route, thread_id){
    return await del("/threads/"+ route + '/' + thread_id)
}
