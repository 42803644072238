import React, { Component } from 'react';
import { Formik } from "formik"

import { Button, Form as FormArea } from 'reactstrap'

import { InputWithLabel } from 'components/01_Atoms/parts/Inputs'
import { signUp } from 'actions/auth' 
import { signUpValidationSchema } from 'actions/form/validationSchema'


class App extends Component {
    constructor() {
        super()
        this.state = {
            validationError: "",
        };
    }

    render() {
        const { onCodeDelivered, Username="",  ...props } = this.props
        const { validationError } = this.state
        
        const userNameReadOnly = Username !== ""

        let initialValues = {
            name: Username,
            email: '',
            password: ''
        }

        const validationSchema = signUpValidationSchema

        const onSubmit = async (values) => {
            var { name, email, password } = values

            signUp(name, password, email,(err) => {
                if (err){
                    this.setState({
                        validationError: err.message
                    })
                } else {
                    onCodeDelivered(name)
                }
            })
        }

        const renderForm = ({handleSubmit, isSubmitting}) => {
            return <FormArea className="text-left" onSubmit={handleSubmit}>
                <h3 className="border-bottom mb-4 font-weight-bolder">アカウントを新規作成</h3>

                {!userNameReadOnly ? 
                    <InputWithLabel title="ユーザー名" className="mb-2" name="name"/> : 
                    <p>ユーザー名: {Username}</p>
                }
                <InputWithLabel title="メールアドレス" className="mb-2" name="email" />
                <InputWithLabel type="password" title="パスワード" className="mb-2" name="password" />
                <InputWithLabel type="password" title="パスワード(確認用)" className="mb-2" name="password_confirm" />

                <Button color="primary" type="submit" disabled={isSubmitting}>仮登録する(検証コード送信)</Button>
                <div className="form-error" >{validationError}</div>
            </FormArea>
        }

        return <div {...props}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                render={renderForm}
            />
        </div>
    }
}

export default App