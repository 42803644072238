import { combineReducers } from 'redux';
import { routerReducer } from "react-router-redux";

export function bbslogs(state={
    data: [],
    isLoading: true,
}, action){
    switch (action.type){
        case "GET_LOGS":
            state.data = action.logs;
            return Object.assign({}, state)
        case "ADD_LOGS":
            state.data = state.data.concat(action.logs);
            return Object.assign({}, state)
        case "CHANGE_LOG":
            let index = state.data.findIndex(item => item.log_id === action.item.log_id)
            state.data[index] = Object.assign({}, state.data[index], action.item)
            return Object.assign({}, state)
        case "CHANGE_IS_LOADING_LOGS":
            state.isLoading = action.isLoading
            return Object.assign({}, state)
        case "BATCH_ADD_LIKES":
            console.log("BATCH_ADD_LIKES", action)
            state.data.forEach(item => {
                const like = action.likes.find(like => like.post_id === item.log_id)
                if (like){
                    item.onw_like_type = like.type
                } else {
                    item.onw_like_type = null
                }
            })
            return Object.assign({}, state)
        default:
            return state;
    }

}

export function thread_info(state = {
    data: {}, 
    isLoading: true,
},action){
    switch (action.type){
        case "ADD_THREAD_INFO":
            state.data = action.thread_info
            return Object.assign({}, state);
        case "CHANGE_THREAD_INFO":
            state.data = Object.assign({}, state.data, action.item)
            return Object.assign({}, state)
        case "CHANGE_IS_LOADING_THREAD_INFO":
            state.isLoading = action.isLoading
            return Object.assign({}, state)
        default:
            return state;
    }
}

export function bbsthreads(state={
    threads: [],
    isLoading: true,
}, action){
    switch (action.type){
        case "ADD_THREADS":
            state.threads = action.threads;
            return Object.assign({}, state);
        case "CHANGE_THREAD":
            let index = state.threads.findIndex(item => item.log_id === action.item.log_id)
            state.threads[index] = Object.assign({}, state.threads[index], action.item)
            return Object.assign({}, state)
            case "CHANGE_IS_LOADING_THREADS":
                state.isLoading = action.isLoading
                return Object.assign({}, state)
            case "BATCH_ADD_LIKES_THREAD":
                state.threads.forEach(item => {
                    const like = action.likes.find(like => like.post_id === item.thread_id)
                    if (like){
                        item.onw_like_type = like.type
                    } else {
                        item.onw_like_type = null
                    }
                })
        default:
            return state;
    }

}

export function user_info(state={
    name: "",
    sub: "",
    idToken: "",
    refreshToken: "",
    refreshTokenExp: 0,
    status: "anonymous",
}, action){
    switch (action.type){
        case "ADD_USER_NAME":
            state.name = action.name
            return Object.assign({}, state)
        case "LOGIN":
            state.idToken = action.tokens.idToken
            state.refreshToken = action.tokens.refreshToken
            state.refreshTokenExp = action.tokens.refreshTokenExp
            state.name = action.tokens.name
            state.sub = action.tokens.sub
            state.status = "login"
            return Object.assign({}, state)
        case "LOGOUT":
            state.idToken = ""
            state.refreshToken = ""
            state.refreshTokenExp = 0
            state.name = ""
            state.sub = ""
            state.status = "anonymous"
            return Object.assign({}, state)
        case "REFRESH":
            state.idToken = action.tokens.idToken
            state.refreshToken = action.tokens.refreshToken
            state.status = "refresh"
            return Object.assign({}, state)
        default:
            return state;
    }
}

export function open_log_info(state={
    log_info: null
},action) {
    switch (action.type){
        case "ADD_LOG_INFO":
            state.log_info = action.log_info
            return Object.assign({}, state)
        case "DELETE_LOG_INFO":
            state.log_info = null
            return Object.assign({}, state)
        default:
            return state;
    }
}

export default combineReducers({
    bbslogs,
    thread_info,
    bbsthreads,
    user_info,
    routerReducer,
    open_log_info
});


