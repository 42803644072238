import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isLoggedIn } from 'actions/auth'

import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';


class App extends Component {

    constructor() {
        super()
        this.state = {
            isOpen: false
        };
    }

    render() {

        const { isOpen } = this.state
        const { user_info } = this.props

        const user_auth = () => {
            if (isLoggedIn()) {
                return <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>{"Hello, " + user_info.name + "!"}</DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem href="/auth/logout">ログアウト</DropdownItem>
                        <DropdownItem href="/auth/changepass">パスワードを変更</DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            } else {
                return <NavLink href="/auth/login">ユーザー認証</NavLink>
            }
        }

        const sitemap = () => {
            return <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>サイトマップ</DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem href="/">ホーム</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem href="/BBS">掲示板</DropdownItem>
                    <DropdownItem href="/Wiki">Wiki</DropdownItem>
                    <DropdownItem href="/Image">画像</DropdownItem>
                    <DropdownItem href="/Data">データ</DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        }



        const toggle = () => {
            this.setState({ isOpen: !isOpen })
        }

        return (
            <div>
                <Navbar color="light" light expand="md">
                    <NavbarBrand href="/">AlgodooOnline [ver 2.0]</NavbarBrand>
                    <NavbarToggler onClick={toggle} />
                    <Collapse isOpen={isOpen} navbar>
                        <Nav className="mr-auto" navbar>
                            {sitemap()}
                        </Nav>
                        <Nav navbar>
                            {user_auth()}
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        logs: state.bbslogs.logs,
        thread_info: state.bbslogs.thread_info,
        user_info: state.user_info,
        isLoading: state.bbslogs.isLoading
    }
};

export default connect(mapStateToProps)(App);
