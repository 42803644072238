import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import AuthTemplate from 'components/04_Templates/AuthTemplate'
import LogInForm from 'components/02_Molecules/AuthForms/LogInForm'

import {
    Button
} from 'reactstrap';

class App extends Component {

    constructor() {
        super()
        this.state = {
            isLoggedIn: false,
            isLoggedInV1: false,
            allowSignUpV2: false,
            v1_name: "",
        };
    }

    render() {
        const { isLoggedIn, isLoggedInV1, allowSignUpV2, v1_name } = this.state

        const handleLogIn = ()=>{
            this.setState({
                isLoggedIn: true
            })
        }

        const hangleV1LogIn = (e)=>{
            this.setState({
                isLoggedInV1: true,
                v1_name: e.name
            })
        }

        const hangleAllowSignUpV2 = (e)=>{
            this.setState({
                allowSignUpV2: true
            })
        }

        const AllowSignUpV2Block = ()=> <div>
            {isLoggedInV1 && allowSignUpV2 && <Redirect to={`/auth/signup/${v1_name}`} />}
            <h4>おかえりなさい!</h4>
            <p>認証方式が更新されました。</p>
            <p>続行するには、新しいアカウントを作成してください。</p>
            <Button onClick={hangleAllowSignUpV2}>現行バージョンのアカウントを作成</Button>
        </div>

        const MainPageBlock = () => <div>
            {isLoggedIn &&  <Redirect to="/" />}
            <LogInForm 
                onLoggedIn={handleLogIn}
                onV1LoggedIn={hangleV1LogIn}
            />
            <div className="mt-2">
                <a href="/auth/signup" className="mr-3">アカウントを新規作成する</a>
            </div>
            <div className="mt-2">
                <a href="/auth/forgotpass">パスワードを忘れた場合</a>
            </div>
        </div>

        return <AuthTemplate
                MainPageBlock={!isLoggedInV1? MainPageBlock : AllowSignUpV2Block}
            />
    }
}

export default App
