import JSZip from 'jszip';

import { blob2dataURL } from 'actions/unzip'


function get_extension(filename) {
    return filename.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2);
}

async function unzipThumbnail(blob) {
    var zip = await JSZip.loadAsync(blob);
    var thumb = await zip.file("thumb.png").async("blob")
    var dataUrl = await blob2dataURL(thumb, "image/png")
    return dataUrl
}

export async function parsePhunData (blob) {
    const filename  = blob.name;
    const ext = get_extension(filename);
    const isPHZ = ext.toLowerCase() === 'phz'
    const filedata = await blob2dataURL(blob, isPHZ? "application/zip" : "text/plain");

    return {
        filename,
        filedata,
        type: ext,
        isPHZ,
        thumb: isPHZ ? await unzipThumbnail(blob) : null
    }
}