import { get, post, del } from 'actions/fetch/fetch'
import { getUserInfo } from 'actions/auth'

export async function getLikes (post_id){
    let data = await get("/like/" + post_id)
    data = data.likes
    data.n_total = data.n_good - data.n_bad
    return data
}

export async function getOwnLike (post_id){
    let data = await get("/like/" + post_id + "/own" )
    data = data.like
    return data
}

export async function postLike (body){
    return await post("/like", body)
}

export async function deleteLike (body){
    return await del("/like", body)
}
