import React from "react";

const Thumbnail = props =>{
    const { className, style, src, alt, height, width, onClick } = props

    const imgStyle = {
        "object-fit": "cover",
        height: height || 200,
        width: width || 200,
    }

    return <div className={className} style={style} onClick={onClick}>
        <img src={src}
            alt={alt}
            className="img-thumbnail"
            style={imgStyle} />
    </div> 
}

export default Thumbnail