import React, { Component } from 'react'

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Icon } from 'react-icons-kit'
import {ic_chevron_left} from 'react-icons-kit/md/ic_chevron_left'
import {ic_chevron_right} from 'react-icons-kit/md/ic_chevron_right'

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <Icon
            className={className} style={style}
            size={30}
            icon={ic_chevron_right}
            onClick={onClick}
            style={{
                ...style,
                color: "gray",
                position: "absolute",
                transform: "translate(-40%, -100%)",
                "text-align": "center",
        }} />
    )
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <Icon
            className={className} style={style}
            size={30}
            icon={ic_chevron_left}
            onClick={onClick}
            style={{
                ...style,
                color: "gray",
                position: "absolute",
                transform: "translate(0%, -100%)",
                "text-align": "center",
            }} />
    );
}

class App extends Component {
    constructor() {
        super()
        this.state = {
            nav1: null,
            nav2: null,
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }


    render() {
        const { className, style } = this.props
        const { focus } = this.state

        var { images, root } = this.props
        images = images || []
        root = root || ""

        const settings_main = {
            speed: 250,
            infinite: true,
            swipeToSlide: true,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            adaptiveHeight: true
        }

        const settings_nav = {
            centerMode: true,
            infinite: true,
            speed: 250,
            slidesToShow: images.length >= 5 ? 5 : images.length,
            swipeToSlide: true,
            adaptiveHeight: true,
            beforeChange: (current, next) => this.setState({ focus: next }),
        }

        return (
            <div className={className} style={style}>
                <Slider
                    {...settings_main}
                    asNavFor={this.state.nav2}
                    ref={slider => (this.slider1 = slider)}
                >
                    {images.map((imageUrl, index) =>
                        <div className="each-slide" key={index}>
                            <img
                                alt={`slide-${index}`}
                                src={root + imageUrl}
                                width={"100%"}
                            />
                        </div>
                    )}
                </Slider>
                <Slider
                    {...settings_nav}
                    asNavFor={this.state.nav1}
                    ref={slider => (this.slider2 = slider)}
                >
                    {images.map((imageUrl, index) =>
                        <div className="each-slide px-1" key={index}>
                            <img
                                alt={`slide-${index}`}
                                src={root + imageUrl}
                                width={100}
                                className="border"
                                style={index === focus ? {
                                    opacity: 1
                                } : {
                                        opacity: 0.5
                                    }}
                            />
                        </div>
                    )}
                </Slider>
            </div>
        )
    }
}
export default App