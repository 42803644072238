import React, { Component } from 'react';

import Like from 'components/01_Atoms/bbs_items/Like'
import ThreadBody from 'components/01_Atoms/bbs_items/ThreadBody'

class App extends Component{
    constructor() {
        super()
        this.state = {
            isFormOpen: true,
        }
    }

    render() {
        const { thread_info:item, isMarkdownEnabled } = this.props

        return <div className="text-left">
            <div className="bbs-content y-border py-3">
                <h1 className="mb-4 font-weight-bold">{item.title}</h1>
                <div className="d-flex flex-row">
                    <Like post_id={item.thread_id} nLike={item.nLike} className="mr-3" />
                    <div className="flex-grow-1" style={{width: '90%'}}>
                        <ThreadBody item={item} isMarkdownEnabled={isMarkdownEnabled} />
                    </div>
                </div>
            </div>
        </div>

    }
}

export default App;
