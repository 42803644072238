import React, { Component } from 'react';

import { Formik } from "formik"
import { connect } from 'react-redux';

import { Button, Form as FormArea } from 'reactstrap';

import { InputWithLabel } from 'components/01_Atoms/parts/Inputs'
import { changePassValidationSchema } from 'actions/form/validationSchema'
import { changePassword } from 'actions/auth' 


class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            validationError: ""
        };
    }

    render() {
        const { user_info, onPasswordChanged, ...props } = this.props;
        const { validationError } = this.state

        const initialValues = {
            name: user_info.name,
            password_old: '',
            password: '',
            password_confirm: '',
        }

        const validationSchema=changePassValidationSchema

        const onSubmit = async (values) => {
            const { name, password_old, password} = values;

            changePassword(name, password_old, password, (err) => {
                if (err) {
                    this.setState({validationError: err.message})
                } else {
                    onPasswordChanged()
                }
            })
        }

        const renderForm = ({handleSubmit, isSubmitting}) => {
            return <FormArea className="text-left" onSubmit={handleSubmit}>
                <h3 className="border-bottom mb-4 font-weight-bolder">パスワードを変更する</h3>

                <InputWithLabel title="ユーザー名" className="mb-2" name="name" />
                <InputWithLabel title="パスワード" type="password" className="mb-2" name="password_old" />
                <InputWithLabel title="新しいパスワード" type="password" className="mb-2" name="password" />
                <InputWithLabel title="新しいパスワード (確認用)" type="password" className="mb-2" name="password_confirm" />

                <Button color="primary" type="submit" disabled={isSubmitting}>パスワードを変更</Button>{' '}
                <div className="form-error" >{validationError}</div>
            </FormArea>
        }

        return <div {...props}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                render={renderForm}
        /></div>
    }
}


const mapStateToProps = state => {
    return {
        user_info: state.user_info,
    }
}

export default connect(mapStateToProps)(App);