import React, { Component } from 'react';
import { connect } from 'react-redux';

import ResInfo from 'components/01_Atoms/bbs_items/ResInfo'
import MarkDownBody from 'components/01_Atoms/bbs_items/MarkdownBody'

import {LogFormWithModal} from 'components/02_Molecules/Forms/LogForm'

import {
    CardText
} from 'reactstrap';


export const LogBodyBase = (props) => {
    const { item, className, style, isMarkdown } = props

    const styleWhiteSpace = {
        "white-space":"pre-wrap", 
        "word-wrap":"break-word"
    }

    const content = item.content_updated || item.content
    
    return <div className={className} style={style}>
        <div class="d-flex mb-3">
            <ResInfo item={item} className="flex-fill" />
        </div>
        {isMarkdown && <MarkDownBody value={content} />}
        {!isMarkdown &&<CardText style={styleWhiteSpace}>
            {item.content_updated? <span dangerouslySetInnerHTML={{__html: content}} /> : content}
        </CardText>}
    </div>
}


class App extends Component{
    constructor() {
        super()
        this.state = {
        }
    }

    render() {
        const { item, className, style, isMarkdown } = this.props


        return <LogFormWithModal 
                className={className + " link"} style={{...style, width: '100%'}}
                item={item} 
                mode="RESPONSE_LOG"
                title="このログに返信する"
            >
                <LogBodyBase item={item} isMarkdown={isMarkdown} />
        </LogFormWithModal>   
    }
}

const mapStateToProps = state => {
    return {
        user_info: state.user_info,
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);