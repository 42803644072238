import React, { Component } from 'react'
import Dropzone, { useDropzone } from 'react-dropzone'

export default class App extends Component {
    constructor() {
        super()
    }

    render(){
        const { onDrop, className, style } = this.props
    
        return <div className={className} style={style}>
            <Dropzone onDrop={acceptedFiles => onDrop(acceptedFiles)}>
                {({getRootProps, getInputProps}) => (
                    <div className="border rounded bg-light" {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p className="text-center text-secondary m-2">
                            ファイルをドラッグ＆ドロップ<br />
                            またはクリックして選択
                        </p>
                    </div>
                )}
            </Dropzone>
        </div>
    }
}