import React, { Component } from 'react';

import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import {
    Button,
    Form as FormArea,
    FormGroup,
    Label,
} from 'reactstrap';

import {isLoggedIn, logOut} from 'actions/auth' 
import AuthTemplate from 'components/04_Templates/AuthTemplate'


class App extends Component {

    constructor(props) {
        super(props);
        this.state = { };
    }

    render() {
        const onSubmitForm = () => {
            logOut()
        }

        const LogOutForm = () => {
            return <FormArea className="text-left">
                <h3 className="border-bottom mb-4 font-weight-bolder">ログアウトする</h3>
                <FormGroup>
                    <Label>下のボタンを押すとログアウトします</Label>
                </FormGroup>
                <Button color="danger" type="button" onClick={onSubmitForm}>Log Out</Button>
            </FormArea>
        }

        return !isLoggedIn()? <Redirect to="/" /> : <AuthTemplate
            MainPageBlock={LogOutForm}
        />
    }
}


const mapStateToProps = state => {
    return {
        user_info: state.user_info,
    }
};

const mapDispatchToProps = dispatch => {
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
