import { 
    getNewLogs, addLogs, batchAddLikes,
    getNewThreadInfo, 
    changeIsLoadingLogs, changeIsLoadingThreadInfo
} from 'redux/actions';
import store from 'redux/store';

import { sanitize, search } from 'actions/search'
import { get, post, sort, put, del, update_array_item } from 'actions/fetch/fetch'

const {dispatch} = store

function eneble_response_parent(data, log_id){
    const log = data.find(item => item.log_id === log_id)

    if (log.response_to){
        data = update_array_item(data, "log_id", log.response_to, {disabled: false})
        return eneble_response_parent(data, log.response_to)
    } else {
        return data
    }
}

export async function search_logs(keyword, param) {
    dispatch(changeIsLoadingLogs(true))
    var { data } = store.getState().bbslogs

    data = data.map(item => {
        item.content_updated = null

        if (item.isDeleted){
            item.disabled = true
            return item
        }

        const searchContent = search(sanitize.encode(item.content), keyword)

        if (searchContent.result){
            item.disabled = false
            item.content_updated = searchContent.value
        } else {
            item.disabled = true
            return item
        }

        return item
    })

    data.forEach(item =>{
        if (!item.isDeleted && !item.disabled){
            data = eneble_response_parent(data, item.log_id)
        }
    })

    data = await sort(data, param)

    dispatch(getNewLogs(data))
    dispatch(changeIsLoadingLogs(false))
}

export async function sort_logs(param){
    dispatch(changeIsLoadingLogs(true))
    var { data } = store.getState().bbslogs

    data = await sort(data, param)

    dispatch(getNewLogs(data))
    dispatch(changeIsLoadingLogs(false))
}

export async function getLogs(thread_id) {
    dispatch(changeIsLoadingLogs(true))

    let data =  await get("/bbslogs/" + thread_id)
    data = data.submitions
    data = await sort(data, "NEWEST_UPDATED")
    dispatch(getNewLogs(data))

    let post_ids = data.filter(item => item.nLike > 0).map(item => item.log_id)
    let likes = await post("/like/own/batch", {post_ids})
    dispatch(batchAddLikes(likes.likes))

    dispatch(changeIsLoadingLogs(false))
}

export async function getThreadInfo(route, thread_id) {
    dispatch(changeIsLoadingThreadInfo(true))

    let data =  await get("/threads/" + route +  "/" + thread_id)
    data = data.thread_info

    dispatch(getNewThreadInfo(data))
    dispatch(changeIsLoadingThreadInfo(false))
}

export async function getResponses (thread_id, response_to){
    let data = await get("/bbslogs/" + thread_id + "/" + response_to + "/responses")
    data = data.responses
    data = await sort(data, "updated_at", false)
    dispatch(addLogs(data))
}

export async function postLog(body, thread_id){
    return await post("/bbslogs/"+thread_id, body)
}

export async function editLog(body, thread_id, log_id){
    return await put("/bbslogs/"+thread_id + '/' + log_id, body)
}

export async function postResponse(body, thread_id, response_to){
    return await post("/bbslogs/"+thread_id + '/' + response_to, body)
}

export async function deleteLog(thread_id, log_id){
    return await del("/bbslogs/"+thread_id + '/' + log_id)
}
