import React, { Component } from 'react'

import {
    Modal, ModalBody, ModalHeader
} from "reactstrap"


class App extends Component {
    constructor() {
        super()
        this.state = {
        };
    }

    render() {        
        const { isOpen, toggle, title, children } = this.props

        return <Modal isOpen={isOpen} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody>{children}</ModalBody>
        </Modal>
    }
}

export default App

export class FormModal extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        };
    }    
    
    render() {
        const { children, className, style, Form, FormAttrs } = this.props
        const { title } = this.props
        const { isOpen } = this.state

        const toggle = () => {
            this.setState({isOpen: !isOpen})
        }

        return <div className={className} style={style} onClick={toggle}>
            {children}
            <App isOpen={isOpen} title={title} toggle={toggle} >
                <Form {...FormAttrs} onSubmit={toggle}/>
            </App>
        </div>
    }
} 