import React, { useState, Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';

import Modal from 'components/01_Atoms/layouts/Modal'
import DeleteForm from "components/02_Molecules/Forms/DeleteForm"
import LogForm from "components/02_Molecules/Forms/LogForm"
import ThreadForm from "components/02_Molecules/Forms/ThreadForm"


const Example = (props) => {
    const { className, style, Head, Menu } = props;
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);


    return (
        <Dropdown className={className} style={style} size="sm" direction="left" isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle caret className="bg-light text-secondary border-secondary">
                {Head || "menu"}
            </DropdownToggle>
            {Menu}
        </Dropdown>
    );
}

export default Example;

export class LogDropdown extends Component {
    constructor() {
        super()
        this.state = {
            isDeleteFormOpen: false,
            isEditFormOpen: false
        };
    }

    render() {
        const { className, style } = this.props;
        const { item } = this.props;
        const { isDeleteFormOpen, isEditFormOpen } = this.state;

        const toggleDeleteForm = () => {
            this.setState({ isDeleteFormOpen: !isDeleteFormOpen})
        }
        
        const toggleEditForm = () => {
            this.setState({ isEditFormOpen: !isEditFormOpen})
        }

        return <Example className={className} style={style}
            Menu={<DropdownMenu>
                <DropdownItem onClick={toggleEditForm}>
                    ログを編集
                </DropdownItem>
                <DropdownItem className="text-danger" onClick={toggleDeleteForm}>
                    ログを削除
                </DropdownItem>
                <Modal isOpen={isDeleteFormOpen} title={"ログを削除する"} toggle={toggleDeleteForm}>
                    <DeleteForm item={item} mode="LOG" />
                </Modal>
                <Modal isOpen={isEditFormOpen} title={"ログを編集する"} toggle={toggleEditForm}>
                    <LogForm item={item} mode="EDIT"/>
                </Modal>
            </DropdownMenu>}
        />   
    }
}



export class ThreadDropdown extends Component {
    constructor() {
        super()
        this.state = {
            isDeleteFormOpen: false,
            isEditFormOpen: false
        };
    }

    render() {
        const { className, style } = this.props;
        const { item } = this.props;
        const { isDeleteFormOpen, isEditFormOpen } = this.state;

        const toggleDeleteForm = () => {
            this.setState({ isDeleteFormOpen: !isDeleteFormOpen})
        }
        
        const toggleEditForm = () => {
            this.setState({ isEditFormOpen: !isEditFormOpen})
        }

        return <Example className={className} style={style}
            Menu={<DropdownMenu>
                <DropdownItem onClick={toggleEditForm}>
                    投稿を編集
                </DropdownItem>
                <DropdownItem className="text-danger" onClick={toggleDeleteForm}>
                    投稿を削除
                </DropdownItem>
                <Modal isOpen={isDeleteFormOpen} title={"投稿を削除する"} toggle={toggleDeleteForm}>
                    <DeleteForm item={item} mode="THREAD" />
                </Modal>
                <Modal isOpen={isEditFormOpen} title={"投稿を編集する"} toggle={toggleEditForm}>
                    <ThreadForm item={item} mode="EDIT" route={item.route}/>
                </Modal>
            </DropdownMenu>}
        />   
    }
}