import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import AuthTemplate from 'components/04_Templates/AuthTemplate'
import ActivateForm from 'components/02_Molecules/AuthForms/ActivateForm'
import SignUpForm from 'components/02_Molecules/AuthForms/SignUpForm'


class App extends Component {

    constructor() {
        super()
        this.state = {
            isCodeDelivered: false,
            isSignUp: false,
        };
    }

    render() {
        const { Username="" } = this.props.match.params
        const { isCodeDelivered, isSignUp } = this.state

        console.log(this.props.match.params)

        const handleCodeDelivered = (name)=>{
            this.setState({
                isCodeDelivered: true,
            })
        }

        const handleActiveted = ()=>{
            this.setState({
                isSignUp: true
            })
        }

        const MainPageBlock = () => <div>
            {!isCodeDelivered? <SignUpForm 
                onCodeDelivered={handleCodeDelivered}
                Username = {Username}
            />:
            <ActivateForm
                onActivated={handleActiveted}
            />}
        </div>

        return isSignUp? <Redirect to="/auth/login" /> : <AuthTemplate
            MainPageBlock={MainPageBlock}
        />
    }
}

export default App
