import React, { Component } from 'react';
import { Formik } from "formik"
import { connect } from 'react-redux';

import { Button, Form as FormArea } from 'reactstrap'

import { InputWithLabel } from 'components/01_Atoms/parts/Inputs'
import { confirmUser } from 'actions/auth' 
import { activateValidationSchema } from 'actions/form/validationSchema'


class App extends Component {
    constructor() {
        super()
        this.state = {
            validationError: "",
        };
    }

    render() {
        const { user_info, onActivated, ...props } = this.props
        const { name } = user_info
        const { validationError} = this.state

        const initialValues = {
            name,
            actKey: '',
        }

        const validationSchema = activateValidationSchema

        const onSubmit = async (values) => {
            const { actKey, name} = values;

            confirmUser(name, actKey,(err)=>{
                if(err){
                    this.setState({
                        validationError: err.message
                    })
                } else {
                    onActivated()
                }
            })  
        }

        const renderForm = ({handleSubmit, isSubmitting}) => {
            return <FormArea className="text-left" onSubmit={handleSubmit}>
                <h3 className="border-bottom mb-4 font-weight-bolder">アカウントを新規作成</h3>

                <p>メールアドレスに送信された検証コードを入力して、ユーザー登録を完了してください</p>

                <InputWithLabel title="ユーザー名" className="mb-2" name="name" />
                <InputWithLabel title="確認コード" className="mb-2" name="actKey" />

                <Button color="primary" type="submit" disabled={isSubmitting}>本登録</Button>
                <div className="form-error" >{validationError}</div>
            </FormArea>
        }

        return <div {...props}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                render={renderForm}
            />
        </div>
    }
}

const mapStateToProps = state => {
    return {
        user_info: state.user_info,
    }
};

export default connect(mapStateToProps)(App);