import React, { Component } from 'react';
import Link from 'components/01_Atoms/layouts/Link'

export default class App extends Component {
    constructor(props) {
        super();
    }

    render() {
        const { className, style, thread_info } = this.props

        const ImagePrev = ({src}) => <img 
            src={src} 
            style = {{
                width: "100%",
                height: "100%",
                "max-height": "350px",
                objectFit: "cover",
            }}
        />

        const href=process.env.REACT_APP_IMAGE_URL +"/" + thread_info.filedata[0]
        const src=process.env.REACT_APP_IMAGE_URL +"/" + thread_info.filedata[0].replace(".phz", "_thumb.png")

        return <div className={className} style={style}>
            <ImagePrev src={src}/>
            <Link href={href} className="rounded border border-warning mt-2" >
                <div className="h5 text-center text-secondary font-weight-bold p-1">ダウンロード</div>
            </Link>
        </div>
    }
}