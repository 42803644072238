import React, { Component } from 'react';

import { Formik } from "formik"
import { connect } from 'react-redux';

import { InputWithLabel } from 'components/01_Atoms/parts/Inputs'
import { loginValidationSchema } from 'actions/form/validationSchema'
import { logIn, isLoggedIn } from 'actions/auth'
import { v1SignIn, updateUserInfo } from 'actions/fetch/user';

import { Button, Form as FormArea } from 'reactstrap';

class App extends Component {

    constructor() {
        super()
        this.state = {
            validationError: "",
            isLoggedInV2: false,
        };
    }

    async componentDidUpdate() {
        const { onLoggedIn } = this.props
        const { isLoggedInV2 } = this.state

        if (isLoggedIn()){
            //ユーザー情報を更新
            await updateUserInfo()
            //親コンポーネントに値渡し
            onLoggedIn()
        }
    }

    render() {
        const { user_info, onLoggedIn, onV1LoggedIn, ...props } = this.props
        const { validationError} = this.state

        const initialValues = {
            name: user_info.name,
            password: ''
        }

        const validationSchema=loginValidationSchema

        const onSubmit = async (values) => {
            const { name, password } = values

            const userStatus = await v1SignIn(name, password)
            if (userStatus.isV1) {
                onV1LoggedIn({name})
            } else {
                logIn(name, password, err=>{
                    if (err) {
                        this.setState({ validationError: err.message })
                    } else {
                        this.setState({ isLoggedInV2: true })
                    }
                })
            }
        }

        const renderForm = ({handleSubmit, isSubmitting}) => {
            return <FormArea className="text-left" onSubmit={handleSubmit}>
                <h3 className="border-bottom mb-4 font-weight-bolder">ログインする</h3>
                <InputWithLabel title="ユーザー名" className="mb-2" name="name" />
                <InputWithLabel title="パスワード" type="password" className="mb-2" name="password" />
                <Button color="primary" type="submit" disabled={isSubmitting}>Log in</Button>{' '}
                <div className="form-error" >{validationError}</div>
            </FormArea>
        }

        return <div {...props}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                render={renderForm}
            />
        </div>
    }
}


const mapStateToProps = state => {
    return {
        user_info: state.user_info,
    }
};

export default connect(mapStateToProps)(App);