import React, { Component } from 'react';
import { connect } from 'react-redux';

import ResInfo from 'components/01_Atoms/bbs_items/ResInfo'
import { ThreadDropdown } from 'components/01_Atoms/parts/Dropdown'
import MarkDownBody from 'components/01_Atoms/bbs_items/MarkdownBody'

import {
    CardText,
} from 'reactstrap';

class App extends Component{
    constructor() {
        super()
        this.state = {
        }
    }

    render() {
        const { item, className, style, isMarkdownEnabled } = this.props
        const { user_info } = this.props

        const styleWhiteSpace = {
            "white-space":"pre-wrap", 
            "word-wrap":"break-word"
        }

        return <div className={className} style={style} >
            <div class="d-flex mb-3">
                <ResInfo item={item} className="flex-fill" />
                {(user_info.sub===item.user_id) && <ThreadDropdown item={item} className="" Head={"menu"} />}
            </div>
            {isMarkdownEnabled? <MarkDownBody value={item.content} />: <CardText style={styleWhiteSpace}>{item.content}</CardText>}
        </div>
        
    }
}

const mapStateToProps = state => {
    return {
        user_info: state.user_info,
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);