import React from "react";
import { connect } from "formik";

import {
    InputGroup,
    InputGroupAddon,
    Input,
    Button,
    Label,
    FormFeedback,
    FormGroup,
} from 'reactstrap';

import SimpleMDE from 'react-simplemde-editor';

import 'easymde/dist/easymde.min.css';


const formInput = ({className, name, children, type="text", rows="10", placeholder, onChange, onBlur, multiple, accept,
        formik: { values, errors, touched, handleChange, handleBlur }}) => {
    return <div className={className}>
        <Input
            type={type}
            name={name}
            id={name}
            onChange={onChange || handleChange}
            onBlur={onBlur || handleBlur}
            multiple = {multiple }
            value={values[name]}
            invalid={touched[name] && errors[name]}
            rows={rows}
            placeholder={placeholder}
        >
            {children}
        </Input>
        <FormFeedback>{errors[name]}</FormFeedback>
    </div>
}

const inputWithLabel = ({title, className, name, type="text", rows="10", placeholder, 
        formik: { values, errors, touched, handleChange, handleBlur }}) => {
    return <FormGroup className={className}>
        <Label for={name}>{title}</Label>
        <FormInput 
            type={type}
            name={name}
            id={name}
            rows={rows}
            placeholder={placeholder}
        />
    </FormGroup>
}

const lineInputWithButton = ({className, name, type="text", rows="10", placeholder, onChange, onBlur, 
        buttonColor, buttonTitle,
        formik: { values, errors, touched, handleChange, handleBlur, isSubmitting }}) => {
    return <InputGroup className={className}>
        <Input
            type={type}
            name={name}
            id={name}
            onChange={onChange || handleChange}
            onBlur={onBlur || handleBlur}
            value={values[name]}
            invalid={touched[name] && errors[name]}
            rows={rows}
            placeholder={placeholder}
        />
        <InputGroupAddon addonType="append">
            <Button color={buttonColor || "primary"} type="submit" size="sm" disabled={isSubmitting}>
                {buttonTitle || "Submit"}
            </Button>
        </InputGroupAddon>
    </InputGroup>
}

const selectInput = ({className, style, name, onChange, onBlur, valueList,
        formik: { values, errors, touched, handleChange, handleBlur, isSubmitting }}) => {
    return <Input 
        type="select"
        className={className} style={style}
        name={name} id={name}
        onChange={onChange || handleChange}
        onBlur={onBlur || handleBlur}
        value={values[name]}
        invalid={touched[name] && errors[name]}>
            {valueList.map(item=>
                <option value={item.value}>{item.name}</option>
            )}
    </Input>
}

const markDownInput = ({className, name, formik: { values, errors, touched, setFieldValue, handleBlur }}) => {
    return <div className={className}>
        <SimpleMDE 
            id={name}
            onChange={(e)=>{
                setFieldValue(name, e)
            }}
            value={values[name]}
            onBlur={handleBlur}
            invalid={touched[name] && errors[name]}
        />
        <FormFeedback>{errors[name]}</FormFeedback>
    </div>
}
export const FormInput = connect(formInput)
export const InputWithLabel = connect(inputWithLabel)
export const LineInputWithButton = connect(lineInputWithButton)
export const SelectInput = connect(selectInput)
export const MarkDownInput = connect(markDownInput)
