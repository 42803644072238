import React, { Component } from 'react';
import { Formik } from "formik"
import { connect } from 'react-redux';

import { Button, Form as FormArea } from 'reactstrap'

import { InputWithLabel } from 'components/01_Atoms/parts/Inputs'
import { confirmPassword } from 'actions/auth' 
import { recoverPassValidationSchema } from 'actions/form/validationSchema'


class App extends Component {
    constructor() {
        super()
        this.state = {
            validationError: "",
        };
    }

    render() {
        const { user_info, onPasswordRecovered, ...props } = this.props
        const { name } = user_info
        const { validationError} = this.state

        const initialValues = {
            actKey: '',
            password: '',
            password_confirm: '',
        }

        const validationSchema = recoverPassValidationSchema

        const onSubmit = async (values) => {
            const { actKey, password} = values;

            confirmPassword(name, actKey, password, (err) => {
                if (err) {
                    this.setState({ validationError: err.message })
                } else {
                    onPasswordRecovered()
                }
            })
        }

        const renderForm = ({handleSubmit, isSubmitting}) => {
            return <FormArea className="text-left" onSubmit={handleSubmit}>
                <h3 className="border-bottom mb-4 font-weight-bolder">パスワードを忘れた場合</h3>

                <p>メールアドレスに送信された検証コードを入力して、パスワードを回復してください</p>

                <InputWithLabel title="検証コード" className="mb-2" name="actKey" />
                <InputWithLabel type="password" title="新しいパスワード" className="mb-2" name="password" />
                <InputWithLabel type="password" title="新しいパスワード(確認用)" className="mb-2" name="password_confirm" />

                <Button color="primary" type="submit" disabled={isSubmitting}>パスワードを変更する</Button>
                <div className="form-error" >{validationError}</div>
            </FormArea>
        }

        return <div {...props}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                render={renderForm}
            />
        </div>
    }
}

const mapStateToProps = state => {
    return {
        user_info: state.user_info,
    }
};

export default connect(mapStateToProps)(App);