import { 
    login, 
    refresh, 
    logout, 
    addUserName
} from 'redux/actions';

import store from 'redux/store';

import {
	CognitoUserPool,
    CognitoUserAttribute,
    AuthenticationDetails,
    CognitoRefreshToken,
	CognitoUser,
} from 'amazon-cognito-identity-js';

import {awsConfig} from 'actions/awsConfig' 

var jwt = require('jsonwebtoken');

const {dispatch} = store


const userPool = new CognitoUserPool({
    UserPoolId: awsConfig.UserPoolId,
    ClientId: awsConfig.ClientId,
})

export function getUserInfo () {
    const state = store.getState()
    return state.user_info
}


export function signUp(name, password, email, callback) {
    const attributeList = [
        new CognitoUserAttribute({
            Name: 'email',
            Value: email,
        })
    ]
    userPool.signUp(name, password, attributeList,null,(err,result)=>{
        if(!err){
            dispatch(addUserName(name))
        }
        callback(err)
    })
}


export function confirmUser(name, actKey, callback){
    const cognitoUser = new CognitoUser({
        Username: name, 
        Pool: userPool,
    })
    cognitoUser.confirmRegistration(actKey, true, (err, redult)=>{
        if (!err) {
            dispatch(addUserName(name))
        }
        callback(err)
    })
}


export function logIn(name, password, callback) {
    const cognitoUser = new CognitoUser({
        Username: name, 
        Pool: userPool,
    })
    const authenticationDetails = new AuthenticationDetails({
        Username: name,
        Password: password
    })
    cognitoUser.authenticateUser(authenticationDetails,{
        onSuccess: (result) => {
            const tokens = {
                idToken: result.getIdToken().getJwtToken(),
                refreshToken: result.getRefreshToken().getToken(),
                refreshTokenExp: new Date().getTime() /1000 + 3600 * 24 * 30,
            }
            const user_info_from_token = jwt.decode(tokens.idToken)
            tokens.name = user_info_from_token["cognito:username"]
            tokens.sub = user_info_from_token["sub"]
            dispatch(login(tokens))
            callback(null)
        },
        onFailure: (err) => {
            callback(err)
        }
    })
}

export function logOut() {
    dispatch(logout())
}

export function changePassword(name, oldpassword, newpassword, callback) {
    const cognitoUser = new CognitoUser({
        Username: name,
        Pool: userPool
    });
    const authenticationDetails = new AuthenticationDetails({
        Username: name,
        Password: oldpassword
    });
    cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
            cognitoUser.changePassword(oldpassword, newpassword, (err, result) => {
                callback(err)
            })
        },
        onFailure: (err) => {
            callback(err)
        }
    })
}

export function forgotPassword(name, callback) {
    const cognitoUser = new CognitoUser({
        Username: name,
        Pool: userPool
    });
    cognitoUser.forgotPassword({
        onSuccess: (data) => {
            dispatch(addUserName(name))
            callback(null)
        },
        onFailure: (err) => {
            callback(err)
        },
    });
}

export function confirmPassword(name, actKey,newpassword, callback){
    const cognitoUser = new CognitoUser({
        Username: name,
        Pool: userPool
    });
    cognitoUser.confirmPassword(actKey, newpassword, {
        onSuccess: () => {
            logIn(name, newpassword, (err) => {
                callback(err)
            })
        },
        onFailure: (err) => {
            callback(err)
        },
    });   
}



function isTimeOver(unixtime){
    let currentTime = new Date().getTime() /1000;
    const diff = unixtime - currentTime
    return (diff < 600 )
}

function isTokenOver(token){
    let decodedToken = jwt.decode(token)
    return isTimeOver(decodedToken.exp)
}

function refreshIdToken(refreshToken, callback){
    const {user_info} = store.getState()
    const cognitoUser = new CognitoUser({
        Username: user_info.name,
        Pool: userPool
    })

    const refresh_token = new CognitoRefreshToken({RefreshToken: refreshToken});

    cognitoUser.refreshSession(refresh_token, (err, result) => {
        if(!err) {
            const tokens = {
                idToken: result.getIdToken().getJwtToken(),
                refreshToken
            }
            dispatch(refresh(tokens))   
        }
        callback(err)
    })
}

export function isLoggedIn(){
    const {user_info} = store.getState()
    const {idToken, refreshToken, refreshTokenExp} = user_info

    if(!idToken){
        return false
    }

    if ( isTokenOver(idToken) ){
        if(!refreshToken || !refreshTokenExp){
            return false
        }
        if(isTimeOver(refreshTokenExp)){
            return false
        }

        refreshIdToken(refreshToken, (err)=>{
            return false
        })
    }
    return true
}