import React, { Component } from 'react'
import { connect } from 'react-redux';

import Loading from 'components/01_Atoms/parts/Loading'
import ThreadListItem from 'components/02_Molecules/ThreadListItem'

class App extends Component {
    constructor() {
        super()
        this.state = {
        };
    }

    render() {
        const {threads, className, isLoading, style} = this.props

        return <div className={className} style={style} >
            {isLoading && <Loading />}
            {!isLoading && threads.map(item => !item.disabled && <ThreadListItem item = {item} className="my-4" />)}
        </div>
    }
}

const mapStateToProps = state => {
    return {
        threads: state.bbsthreads.threads,
        isLoading: state.bbsthreads.isLoading
    }
};

export default connect(mapStateToProps)(App);
