import React from 'react';
import marked from "marked";

const deniedTagCondition = /^<\/?(script|style|link|iframe|embed|object|html|head|meta|body|form|input|button)/i
//const deniedAttrCondition = /^(on.+|style|href|action|id|class|data-.*)/i
const deniedAttrCondition = /^(on.+|href|action|id|data-.*)/i

const escape = (txt) => {
    if (txt.match(deniedTagCondition) || txt.indexOf('<!') === 0 || txt.indexOf('<?') === 0 || txt.indexOf('<\\') === 0) { return '' }
    if (txt.indexOf('</') === 0) { return txt }
    let outer = document.createElement('div')
    outer.innerHTML = txt
    let el = outer.querySelector('*')
    if (!el) { return '' }

    let attrs = []
    el.getAttributeNames().map(attr => {
        if (attr.match(deniedAttrCondition)) {
            el.removeAttribute(attr)
            return
        }
        attrs.push(`${attr}="${el.getAttribute(attr)}"`)
    })
    return `<${el.tagName} ${attrs.join(' ')}>`
}


// こんな感じで指定すると防げる
marked.setOptions({
    sanitize: true,
    sanitizer: escape,
})


export default function MarkDownBody(props) {
    const { className, style, value } = props;
    return <div className={className} style={style} >
        <span dangerouslySetInnerHTML={{ __html: marked(value || "") }} />
    </div>
}