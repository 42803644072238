import React, { Component } from 'react';

import Navbar from 'components/01_Atoms/parts/Navbar';
import Breadcrumb from 'components/01_Atoms/layouts/Breadcrumb'

export default class App extends Component {
    constructor(){
        super()
        this.state = {}
    }
    render(){
        const { MainPageBlock, BreadcrumbRoutes } = this.props
        
        return (
            <div>
                <Navbar />
                <div className="container p-3 border-right border-left">
                    {BreadcrumbRoutes && <Breadcrumb routes={BreadcrumbRoutes} />}
                    <MainPageBlock />
                </div>
            </div>
        )
    }
}