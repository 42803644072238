import React, { Component } from 'react'

import BBSListPage from 'components/05_Pages/BBSListPage'
import BBSDetailPage from 'components/05_Pages/BBSDetailPage'
import Home from 'components/05_Pages/Home';

import SignUp from 'components/05_Pages/SignUpPage'
import LogIn from 'components/05_Pages/LogInPage'
import LogOut from 'components/05_Pages/LogOutPage'
import ChangePass from 'components/05_Pages/ChangePassPage'
import ForgotPass from 'components/05_Pages/ForgotPassPage'

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

class App extends Component {
    constructor(){
        super()
        this.state = {}
    }
    render(){
        return <BrowserRouter>
        <Switch>
            <Route exact={true} path="/" component={Home} />

            <Route exact={true} path="/i/:route" component={BBSListPage} />
            <Route path="/i/:route/:thread_id" component={BBSDetailPage} />

            <Route path="/auth/signup/:Username" component={SignUp} />
            <Route path="/auth/signup" component={SignUp} />
            <Route path="/auth/login" component={LogIn} />
            <Route path="/auth/logout" component={LogOut} />
            <Route path="/auth/changepass" component={ChangePass} />
            <Route path="/auth/forgotpass" component={ForgotPass} />

            <Route component={() => <Redirect to="/" />} />
        </Switch>
    </BrowserRouter>
        
    }
}

export default App