import React, { Component } from 'react';
import 'static/css/App.css';

import { connect } from 'react-redux';
import MainTemplate from 'components/04_Templates/MainTemplate'

import SearchForm from 'components/02_Molecules/Forms/SearchForm'
import {LogFormWithModalAndButton} from 'components/02_Molecules/Forms/LogForm'
import LogListBlock from 'components/03_Organisms/LogListBlock'
import BBSThreadInfoBlock from 'components/03_Organisms/ThreadInfoBlock'
import { isLoggedIn } from 'actions/auth';

import { getLogs, getThreadInfo
} from 'actions/fetch/log'

class App extends Component {
    constructor() {
        super()
        this.state = {
        };
    }

    async componentDidMount() {
        const { route, thread_id } = this.props.match.params
        await getThreadInfo(route, thread_id)
        if (isLoggedIn()){
            await getLogs(thread_id)
        }
    }

    render() {
        const { route } = this.props.match.params

        const { thread_info } = this.props;

        const MainPageBlock =(props) => {
            const {className, style} = props
            return <div className={className} style={style}>
                <BBSThreadInfoBlock className="mb-4" />
                <LogFormWithModalAndButton className="mb-4 pb-4 border-bottom" />
                <SearchForm className="mb-4" />
                <LogListBlock />
            </div>
        }
        
        return <MainTemplate
            BreadcrumbRoutes={[
                {title: route ,url: `/i/${route}`},
                {title: thread_info.data.title}
            ]}
            MainPageBlock={MainPageBlock}
        />
    }

}

const mapStateToProps = state => {
    return {
        thread_info: state.thread_info,
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
