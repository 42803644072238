import React from 'react'

import { omittedContent } from 'actions/search'

import Link from 'components/01_Atoms/layouts/Link'
import Like from 'components/01_Atoms/bbs_items/Like'
import ResInfo from 'components/01_Atoms/bbs_items/ResInfo'

import {
    Container, Row, Col
} from 'reactstrap'

const ThreadListItem = (props) => {
    const { item, className, style} = props

    const content = item.content_updated || item.content
    const title = item.title_updated || item.title

    const isMarkdownEnabled = ["WIKI","IMAGE","DATA"].includes(item.route) 
    const isImageEnabled = ["IMAGE"].includes(item.route) 
    const isDataEnabled = ["DATA"].includes(item.route) 

    let contentWidth = 11
    if (isImageEnabled){
        contentWidth -= 2
    }
    if (isDataEnabled){
        contentWidth -= 2
    }

    const ImagePrev = ({src}) => <img 
            src={src} 
            style = {{
                width: "100%",
                height: "100%",
                "max-height": "200px",
                objectFit: "cover",
            }}
        />

    return <div className={className} style={style}>
        <Container className="border-top border-bottom">
            <Row>
            {isImageEnabled && <Col className="p-0" xl="2" xs="12">
                <ImagePrev src={process.env.REACT_APP_IMAGE_URL +"/" + item.filedata[0]}/>
            </Col>}
            {isDataEnabled && <Col className="p-0" xl="2" xs="12">
                <ImagePrev src={process.env.REACT_APP_IMAGE_URL +"/" + item.filedata[0].replace(".phz", "_thumb.png")}/>
            </Col>}
            <Col className="" xl="1" xs="2">
                <Like post_id={item.thread_id} nLike={item.nLike} className="mx-2 my-3" />
            </Col>
            <Col className="pl-0" xl={contentWidth+""} xs="10">
                <Link className="pl-0 py-3 " href={`/i/${item.route}/${item.thread_id}`}>
                    <h3 className="font-weight-bold mb-2">
                        {item.title_updated?
                            <span dangerouslySetInnerHTML={{ __html: title}} />: title
                        }
                    </h3>
                    <ResInfo className="mb-2" item={item} />
                    <div className="content">
                        {item.content_updated?
                            <span dangerouslySetInnerHTML={{__html: content}} /> : omittedContent(content)
                        }
                    </div>
                </Link>
            </Col>
            </Row>
        </Container>
    </div>
}

export default ThreadListItem