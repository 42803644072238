import React, { Component } from 'react'

import Loading from "components/01_Atoms/parts/Loading"
import DownloadPHZ from 'components/01_Atoms/bbs_items/DownloadPHZ'
import SlideShow from 'components/01_Atoms/parts/SlideShow'
import ThreadInfoBody from 'components/02_Molecules/ThreadInfoBody'

import { connect } from 'react-redux';

class App extends Component {
    constructor() {
        super()
        this.state = {
            isFormOpen: false,
        };
    }

    render() {
        const { className, style, isLoading, thread_info } = this.props;

        const isMarkdownEnabled = ["WIKI","IMAGE","DATA"].includes(thread_info.route) 
        const isImageEnabled = ["IMAGE"].includes(thread_info.route) 
        const isDataEnabled = ["DATA"].includes(thread_info.route) 

        const MainBlock =(props) => {
            const {className, style } = props
            return <div className={className} style={style}>
                {isDataEnabled && <DownloadPHZ thread_info={thread_info} />}
                <ThreadInfoBody thread_info={thread_info} isMarkdownEnabled={isMarkdownEnabled} />
                {isImageEnabled && <SlideShow images={thread_info.filedata} root={process.env.REACT_APP_IMAGE_URL +"/"} />}
            </div>
        }

        return <div className={className} style={style}>
            {isLoading? <Loading />: <MainBlock />}
        </div>
    }

}

const mapStateToProps = state => {
    return {
        thread_info: state.thread_info.data,
        isLoading: state.thread_info.isLoading,
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
