import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import AuthTemplate from 'components/04_Templates/AuthTemplate'
import ForgotPassForm from 'components/02_Molecules/AuthForms/ForgotPassForm'
import RecoverPassForm from 'components/02_Molecules/AuthForms/RecoverPassForm'


class App extends Component {

    constructor() {
        super()
        this.state = {
            isCodeDelivered: false,
            isPasswordRecovered: false,
            name: null
        };
    }

    render() {
        const { isCodeDelivered, isPasswordRecovered, name} = this.state

        const handleCodeDelivered = (name)=>{
            this.setState({
                isCodeDelivered: true,
                name
            })
        }

        const handlePasswordRecovered = ()=>{
            this.setState({
                isPasswordRecovered: true
            })
        }

        const MainPageBlock = () => <div>
            {!isCodeDelivered? <ForgotPassForm 
                onCodeDelivered={handleCodeDelivered}
            />:
            <RecoverPassForm
                name={name}
                onPasswordRecovered={handlePasswordRecovered}
            />}
        </div>

        return isPasswordRecovered? <Redirect to="/" /> : <AuthTemplate
            MainPageBlock={MainPageBlock}
        />
    }
}

export default App
