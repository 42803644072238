import { Formik } from "formik"
import React, { Component } from 'react';

import {
    Button,
    Form as FormArea,
} from 'reactstrap';

import { connect } from 'react-redux';

import OpenButton from 'components/01_Atoms/parts/OpenButton'
import { FormInput, MarkDownInput } from 'components/01_Atoms/parts/Inputs'
import Dropzone from 'components/01_Atoms/parts/Dropzone'
import { FormModal } from 'components/01_Atoms/layouts/Modal'
import { NeedLogin } from 'components/01_Atoms/bbs_items/NeedLogin'
import Thumbnail from 'components/01_Atoms/parts/Thumbnail'

import { getThreads, postThread, editThread } from 'actions/fetch/thread';
import { file2jpegUrl } from 'actions/form/file'
import { parsePhunData } from 'actions/phz'
import { threadValidationSchema } from "actions/form/validationSchema"


import {
    isLoggedIn, 
} from 'actions/auth' 



class App extends Component {
    constructor() {
        super()
        this.state = {
            validationError: "",
            isFileLoading: false,
            filedata: [],
            filename: [],
            thumbs: []
        };
    }



    render() {
        let { user_info, route,
            onSubmit, item, mode,
            className, style
        } = this.props;
        const { validationError, isFileLoading,  filedata, filename, thumbs } = this.state

        const isMarkdownEnabled = ["WIKI","IMAGE","DATA"].includes(route) 
        const isPhotoEnabled = ["IMAGE"].includes(route) 
        const isDataEnabled = ["DATA"].includes(route) 
        const isEditMode = mode==="EDIT"

        let initialValues = {
            title: '',
            content: '',
        }

        if (isEditMode) {
            initialValues = Object.assign({},initialValues, {
                title: item.title,
                content: item.content
            })
        }

        const onThreadSubmit = async (values, { resetForm, errors }) => {
            let res = {}

            if (isEditMode){
                let body = {
                    submition: {
                        title: values.title,
                        content: values.content,
                    }
                };
                
                res = await editThread(body, route, item.thread_id)

            } else {
                const body = {
                    submition: {
                        title: values.title,
                        user_name: user_info.name,
                        user_id: user_info.sub,
                        content: values.content,
                        filedata
                    }
                };
                
                res = await postThread(body, route);
            }

            if (res.result) {
                await getThreads(route)
                resetForm()
                onSubmit()
            } else {
                this.setState({ validationError: res.ErrorMessage })
            }
        }

        const handleDropPhoto = async(acceptedFiles)=>{
            this.setState({ isFileLoading: true })

            console.log(acceptedFiles)

            for(const file of acceptedFiles){
                var { filedata, filename, thumbs } = this.state

                const dataUrl = await file2jpegUrl(file)
                filedata.push(dataUrl)
                filename.push(file.name)
                thumbs.push(dataUrl)

                this.setState({ filedata, filename, thumbs })
            }
            this.setState({ isFileLoading: false })
        }

        const handleDropData = async(acceptedFiles)=>{
            this.setState({ isFileLoading: true })

            for(const file of acceptedFiles){
                const {filename, filedata, thumb} = await parsePhunData(file)

                console.log(filedata)

                this.setState({ 
                    filedata: [filedata],
                    filename: [filename],
                    thumbs: thumb? [thumb] : []
                })
            }
            this.setState({ isFileLoading: false })
        }

        const Filenames = props => {
            const { files, className, style} = props
            return <div className={className} style={style}>
                <p className="mb-2">
                    {(files || []).length}件のファイルを選択しています
                </p>
                {files.map(file => <p className="mb-2">{file} </p>)}
            </div>
        }

        const renderThreadForm = ({values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting}) => (
            <FormArea className="text-left" onSubmit={handleSubmit}>
                {isPhotoEnabled && !isEditMode && <Dropzone className="mb-2" onDrop={handleDropPhoto}/>}
                {isDataEnabled && !isEditMode && <Dropzone className="mb-2" onDrop={handleDropData}/>}
                { (isPhotoEnabled || isDataEnabled) && !isEditMode && <div className="d-flex flex-wrap">
                    {thumbs.map((src, index) =><Thumbnail
                        className="mr-2 mb-2"
                        src={src}
                        key={index}
                        height={100}
                        width={100}
                    />)}
                </div> }
                {(isPhotoEnabled || isDataEnabled) && <Filenames files={filename} />}
                <FormInput className="mb-2" name="title" placeholder="Title" />
                {isMarkdownEnabled? 
                    <MarkDownInput className="mb-2" name="content" />:
                    <FormInput className="mb-2" type="textarea" name="content" placeholder="Content" />
                }
                <Button color="primary" type="submit" disabled={isFileLoading || isSubmitting}>submit</Button>{' '}
                <div className="form-error" >{validationError}</div>
            </FormArea>
        )

        return <div className={className} style={style}>
            {isLoggedIn()? <Formik
                initialValues={initialValues}
                validationSchema={threadValidationSchema}
                onSubmit={onThreadSubmit}
                render={renderThreadForm}
            /> : <NeedLogin />}
        </div>
    }
}


const mapStateToProps = state => {
    return {
        threads: state.bbsthreads.threads,
        user_info: state.user_info,
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};


const ThreadForm = connect(mapStateToProps, mapDispatchToProps)(App)
export default ThreadForm


export const ThreadFormWithModalAndButton = (props) => {
    let { className, style, title, ...FormAttrs } = props

    title = title || "スレッドを投稿する"

    return <FormModal className={className} style={style} 
            title={title} 
            Form={ThreadForm}
            FormAttrs={FormAttrs}
        >
        <OpenButton info={title} />
    </FormModal>
}