import React from 'react';
import ReactDOM from 'react-dom';

import 'static/css/index.css';
import 'static/css/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';


import * as serviceWorker from 'serviceWorker';

import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import store from 'redux/store'

import App from 'App'

ReactDOM.render(
    <Provider store={store}><CookiesProvider>
        <App />
    </CookiesProvider></Provider>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
