import React, { Component } from 'react'

const Link = (props) => {
    const {children, href, className, style} = props

    return ( <div className={className} style={style} >
            <div className="link text-left">
                {children}
                <a href={href || "/"}>
                    <div display="None"></div>
                </a>
            </div>
    </div>            
    )
}

export default Link