import React, { Component } from 'react';
import ReactLoading from 'react-loading';

import { Icon } from 'react-icons-kit'
import {arrowUp} from 'react-icons-kit/icomoon/arrowUp'
import {arrowDown} from 'react-icons-kit/icomoon/arrowDown'

import { getLikes, getOwnLike, postLike, deleteLike } from 'actions/fetch/like' 
import { connect } from 'react-redux';

import { isLoggedIn } from 'actions/auth'

class App extends Component{
    constructor() {
        super()
        this.state = {
            n_good: 0,
            n_bad: 0,
            n_total: "...",
            type: null, 
        }
    }

    async componentDidMount(){
        const { post_id, nLike, onw_like_type } = this.props
        this.setState({type: onw_like_type})
                
        if (nLike || nLike === 0) {
            this.setState({
                n_total: nLike
            })
        } else {
            this.setState(await getLikes(post_id))
        }
        
        const isTypeLoaded = onw_like_type || (onw_like_type===null)
        if (isLoggedIn() && !isTypeLoaded) {
            const { type } = await getOwnLike(post_id)
            this.setState({type})
        } else if (isTypeLoaded){
            this.setState({type: onw_like_type})
        }
    }

    render() {
        const { isLoading, n_total, type } = this.state
        const { className, user_info, post_id } = this.props

        const colors = {
            normal: { color: "#aaaaaa" },
            up: { color: "#00ff88" },
            down: { color: "#ff0000" },
        }

        const handleGood = () => {
            const body = {
                post_id,
                type: 'good',
                user_id: user_info.sub
            }

            if (isLoggedIn()){
                if(!type){
                    this.setState({ 
                        type: 'good',
                        n_total: n_total + 1,
                    })
                    postLike(body)
                } else if (type === 'bad'){
                    this.setState({ 
                        type: 'good',
                        n_total: n_total + 2,
                    })
                    postLike(body)
                } else {
                    this.setState({ 
                        type: null,
                        n_total: n_total - 1,
                    })
                    deleteLike(body)
                }
            }
        }
        const handleBad = () => {
    
            const body = {
                post_id,
                type: 'bad',
                user_id: user_info.sub
            }
            if (isLoggedIn()){
                if(!type){
                    this.setState({ 
                        type: 'bad',
                        n_total: n_total - 1,
                    })
                    postLike(body)
                } else if (type === 'good'){
                    this.setState({ 
                        type: 'bad',
                        n_total: n_total - 2,
                    })
                    postLike(body)
                } else {
                    this.setState({ 
                        type: null,
                        n_total: n_total + 1,
                    })
                    deleteLike(body)
                }
            }
        }

        var color_good = type==="good" ? colors.up : colors.normal
        var color_bad = type==="bad" ? colors.down : colors.normal

        if (isLoading){
            return  <ReactLoading className="m-auto" type={"spin"} color={"#ccc"} height={'2%'} width={'2%'} />
        } else {
            return <div className={className}>
                <div className="text-center mb-1">
                    <Icon size={20} icon={arrowUp} style={color_good} onClick={handleGood}/>
                </div>
                <div className="text-center text-muted">
                    <small>{n_total}</small>
                </div>
            </div>
        }
    }
}

const mapStateToProps = state => {
    return {
        user_info: state.user_info,
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
