import * as Yup from 'yup';

const requiredString　= (name) => { 
    return Yup.string().required(`${name}を入力してください`)
}

const limitedString= (name, length) => {
    return requiredString(name).max(length, `長さ制限：${length.toLocaleString()}文字`)
}

//投稿関連
const content = limitedString("内容", 32000)

export const logValidationSchema = Yup.object().shape({
    content
})

export const threadValidationSchema = Yup.object().shape({
    title: limitedString("タイトル", 32),
    content
})

export const deleteLogValidationSchema = Yup.object().shape({
    deletepass: Yup.string().oneOf(["削除", "delete"]).required()
})


//ユーザー認証関連
const password = requiredString("パスワード").min(8, "8文字以上のパスワードを入力して下さい")
const name = requiredString("ユーザー名")
const password_confirm = Yup.string().oneOf([Yup.ref('password')], 'passwordが一致しません。')
const actKey = requiredString("検証コード")
const email = requiredString("メールアドレス").email('形式がEmailアドレスではありません')
const password_v1comp = requiredString("パスワード")

export const loginValidationSchema = Yup.object().shape({
    name, password: password_v1comp
})

export const changePassValidationSchema = Yup.object().shape({
    name, 
    password_old: password,
    password, 
    password_confirm
})

export const fotgotPassValidationSchema = Yup.object().shape({
    name
})

export const recoverPassValidationSchema = Yup.object().shape({
    actKey, password, password_confirm
})

export const signUpValidationSchema = Yup.object().shape({
    name, email, password, password_confirm
})

export const activateValidationSchema = Yup.object().shape({
    name, actKey
})