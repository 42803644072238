import { 
    addUserName
} from 'redux/actions';
import { getUserInfo } from 'actions/auth'

import { get, post, sort, put, del } from 'actions/fetch/fetch'

import store from 'redux/store';
const {dispatch} = store


/*
返り値
isV2 ... ユーザー名がcognitoで登録されているかどうか
isV1 ... 旧版のデータベースで登録されたかどうか
*/
export const v1SignIn = async (Username, v1_password) => {
    const submition = {
        Username, v1_password
    }
    const res = await post('/user/signin', {submition});
    dispatch(addUserName(Username))
    return res;
}

//ユーザー情報のトークンから、DBを更新
export const updateUserInfo = async () => {
    const {user_info} = store.getState()
    const {idToken, refreshToken, refreshTokenExp} = user_info

    const submition = {idToken}
    const res = await post('/user/update', {submition});
    return res;
}