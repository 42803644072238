import { Formik } from "formik"
import React, { Component } from 'react';

import {
    Form as FormArea,
} from 'reactstrap';

import { SelectInput, LineInputWithButton } from 'components/01_Atoms/parts/Inputs'

import { sort_logs, search_logs } from "actions/fetch/log"
import { sort_threads, search_threads } from "actions/fetch/thread"


class DeleteForm extends Component {
    constructor() {
        super()
        this.state = {
        };
    }

    render() {
        const { className, style, onSubmit, mode } = this.props

        const initialValues = {
            keyword: '',
        }

        const onFormSubmit = async (values) => {
            if (mode === "THREAD") {
                search_threads(values.keyword)
            } else {
                search_logs(values.keyword)
            }
        }

        const onChangeSortPattern = async (e) => {
            const { value } = e.target
            if (mode === "THREAD") {
                sort_threads(value)
            } else {
                sort_logs(value)
            }
        }

        const valueList = [{
            name: '並べ替え',
            value: ''
        },{
            name: '新着 (更新日時)',
            value: 'NEWEST_UPDATED'
        },{
            name: '新着 (投稿日時)',
            value: 'NEWEST_CREATED'
        },{
            name: '古い順 (更新日時)',
            value: 'OLDEST_UPDATED'
        },{
            name: '古い順 (投稿日時)',
            value: 'OLDEST_CREATED'
        },{
            name: '高評価',
            value: 'BEST'
        },{
            name: 'コメント数',
            value: 'MOST_RESPONSED'
        }]

        const renderForm = ({handleSubmit}) => (
            <FormArea className="text-left" onSubmit={handleSubmit}>
                <LineInputWithButton className="mb-3" name="keyword" placeholder="返信を検索する" />
                <SelectInput className="mb-3 w-50" name="select" valueList={valueList} onChange={onChangeSortPattern} />
            </FormArea>
        )

        return <div className={className} style={style}>
            <Formik
                initialValues={initialValues}
                onSubmit={onFormSubmit}
                render={renderForm}
            />
        </div>
    }
}

export default DeleteForm