import { getUserInfo } from 'actions/auth'

const headers = {
    'x-api-key': process.env.REACT_APP_API_KEY,
    'authorization': getUserInfo().idToken
}

const headers_post = Object.assign({}, headers,{
    "Content-Type":"application/json"
})


export function serverSideValidationError(res){
    if(res.result){
        return ""
    }else{
        let ErrorMessage = "Server-side Error\n"
        
        Object.keys(res.errors).forEach( function(value){
            ErrorMessage += "  " + value + " : " + this[value][0] +"\n"
        },res.errors)

        return ErrorMessage
    }
}


export async function get(path) {
    let res = await fetch(process.env.REACT_APP_API_URL + path, { 
        method: "GET",
        mode: 'cors',
        headers: headers
    })
    res = await res.json()
    return res
}

export async function post(path, body){
    let res = await fetch(process.env.REACT_APP_API_URL + path, { 
        method: "POST",
        headers: headers_post,
        body: JSON.stringify(body),
        mode: 'cors' })
    res = await res.json()
    res.ErrorMessage = serverSideValidationError(res)
    return res
}

export async function put(path, body){
    let res = await fetch(process.env.REACT_APP_API_URL + path, { 
        method: "PUT",
        headers: headers_post,
        body: JSON.stringify(body),
        mode: 'cors' })
    res = await res.json()
    res.ErrorMessage = serverSideValidationError(res)
    return res
}

export async function del (path, body={}){
    let res = await fetch(process.env.REACT_APP_API_URL + path, { 
        method: "DELETE",
        headers: headers_post,
        body: JSON.stringify(body),
        mode: 'cors' })
    res = await res.json()
    res.ErrorMessage = serverSideValidationError(res)
    return res
}

export function sort_array(array, Key = "created_at", isLow2High = true) {
    return array.sort(
        function (a, b) {
            var r = 0;
            if (a[Key] < b[Key]) { r = -1; }
            else if (a[Key] > b[Key]) { r = 1; }

            if (isLow2High) {
                return r;
            }
            else {
                return r * -1;
            }
        })
}

export async function sort(data, param){
    switch (param) {
        case "NEWEST_UPDATED":
            return await sort_array(data, "updated_at", false)

        case "OLDEST_UPDATED":
            return await sort_array(data, "updated_at", true)

        case "NEWEST_CREATED":
            return await sort_array(data, "created_at", false)

        case "OLDEST_CREATED":
            return await sort_array(data, "created_at", true)

        case "BEST":
            return await sort_array(data, "nLike", false)

        case "MOST_RESPONSED":
            return await sort_array(data, "nRes", false)

        default:
            return data
    }
}

export function update_array_item(array, key, value, query){
    array = array.map(item =>{
        if(item[key] === value){
            return Object.assign({}, item, query)
        } else {
            return item
        }
    })
    
    return array
}