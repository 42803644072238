import React, { Component } from 'react';

import LogBody from 'components/01_Atoms/bbs_items/LogBody'

import Like from 'components/01_Atoms/bbs_items/Like'
import { LogDropdown } from 'components/01_Atoms/parts/Dropdown'
import { connect } from 'react-redux';

import {
    CardText,
} from 'reactstrap';

const LogBodyDeleted = (props) => {
    const { className, style } = props

    const styleWhiteSpace = {
        "white-space":"pre-wrap", 
        "word-wrap":"break-word"
    }

    return <div className={className} style={style}>
        <CardText className="text-black-50" style={styleWhiteSpace}>この返信は削除されました</CardText>
    </div>
}

class App extends Component{
    constructor() {
        super()
        this.state = {
            showResponse: false
        }
    }

    render() {
        const { item, logs } = this.props
        const { user_info, className, style } = this.props
        const { showResponse } = this.state

        const responses = logs.filter((log) => {
            if (log.response_to){
                return log.response_to === item.log_id && !log.disabled
            }
        })

        const LogBodyPart = ()=><div className="d-flex flex-row py-3">
            <Like post_id={item.log_id} nLike={item.nLike} onw_like_type={item.onw_like_type} className="mr-3"/>
            <LogBody className="flex-grow-1" item={item} />
            {(user_info.sub===item.user_id) && <LogDropdown item={item} className="" Head={"menu"} />}
        </div>

        return <div className={className} style={style}>
            <div className="text-left border-top">
                {item.isDeleted? <LogBodyDeleted className="py-3" /> : <LogBodyPart />}
                <div className="pl-4 border-left">
                    {!showResponse && (responses.length > 0) && <div 
                        onClick={(e)=>this.setState({showResponse: true})}
                        className="text-primary cursor-pointer"
                    >
                            {responses.length}件の返信
                        </div>}
                    {showResponse && responses.map(response => <LogItem item={response} isClickable={true}/>)}
                </div>
            </div>
        </div> 
    }
}

const mapStateToProps = state => {
    return {
        logs: state.bbslogs.data,
        user_info: state.user_info,
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

const LogItem = connect(mapStateToProps, mapDispatchToProps)(App)

export default LogItem;
