import Jimp from 'jimp';

export const getBase64 = (file) => {
    return new Promise(resolve =>{
        var reader = new FileReader()
        reader.onload = (e) => {
            resolve(e.target.result)
        };
        reader.readAsDataURL(file)
    })
}

export const getJpegBase64 = (buffer)=> { 
    return new Promise(resolve =>{
        Jimp.read(buffer).then(image =>{
            image.getBase64(Jimp.MIME_JPEG, (err,src)=>{
                resolve(src)
            })
        })
    })
}

export const file2jpegUrl = async (file) => {
    const url = await getBase64(file)
    return await getJpegBase64(url)
}