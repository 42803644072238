const Unzip= require('zlibjs/bin/unzip.min').Zlib.Unzip
const FileType = require('file-type');

// BinaryString -> DataURI
function bStr2dataURI(b_str){
	return "data:application/octet-stream;base64," + btoa(b_str)
}

// DataURI -> BinaryString
function dataURI2bStr(data){
	return atob(data.split(",")[1])
}

// BinaryString, UintXXArray, ArrayBuffer -> Blob
function toBlob(val){
	return new Blob([val], {type: "application/octet-stream"})
}

// Blob -> ArrayBuffer, BinaryString, DataURL, text
function read(blob){
	var fr = new FileReader()
	var pr = new Promise((resolve, reject) => {
		fr.onload = eve => {
			resolve(fr.result)
		}
		fr.onerror = eve => {
			reject(fr.error)
		}
	})

	return {
		arrayBuffer(){
			fr.readAsArrayBuffer(blob)
			return pr
		},
		binaryString(){
			fr.readAsBinaryString(blob)
			return pr
		},
		dataURL(){
			fr.readAsDataURL(blob)
			return pr
		},
		text(){
			fr.readAsText(blob)
			return pr
		},
	}
}

export async function blob2dataURL(blob, mime=null){
	const body = await read(blob).arrayBuffer()
	return await arrayBuffer2DataURL(body, mime)
}

async function dataURL2Buffer(dataURL){
    const fileData = dataURL.replace(/^data:\w+\/\w+;base64,/, '')
    return new Buffer(fileData, 'base64')
}


function _arrayBufferToBase64( buffer ) {
	var binary = '';
	var bytes = new Uint8Array( buffer );
	var len = bytes.byteLength;
	for (var i = 0; i < len; i++) {
		binary += String.fromCharCode( bytes[ i ] );
	}
	return window.btoa( binary );
}

async function arrayBuffer2DataURL(buffer, mime=null){
	var body = _arrayBufferToBase64(buffer)
	
	if (!mime){
		mime = await FileType.fromBuffer(buffer).mime
	}

    return `data:${mime};base64,${body}`
}

export async function unzip_blob(blob) {
	var bff = await read(blob).arrayBuffer()
	return await unzip_buffer(bff)
}

export async function unzip_image_url (dataUrl){
    const bff = await arrayBuffer2DataURL(dataUrl)
    return await unzip_buffer(bff)
}

export async function unzip_buffer(bff){
	var unzip= new Unzip(bff)
	console.log(unzip)
    let result = []
    unzip.getFilenames().forEach( async (filename)=>{
        var buffer = new Buffer(unzip.decompress(filename))
        result.push(await arrayBuffer2DataURL(buffer))
    })
    return result
}