import { Formik } from "formik"
import React, { Component } from 'react';

import {
    Button,
    Form as FormArea,
} from 'reactstrap';


import { FormInput } from 'components/01_Atoms/parts/Inputs'
import { deleteLogValidationSchema } from "actions/form/validationSchema"
import { FormModal } from 'components/01_Atoms/layouts/Modal'
import { deleteLog,  getLogs} from 'actions/fetch/log';
import { deleteThread } from 'actions/fetch/thread';


class DeleteForm extends Component {
    constructor() {
        super()
        this.state = {
            validationError: "",
        };
    }

    render() {
        const { item, className, style, onSubmit, mode } = this.props
        const { validationError } = this.state

        const initialValues = {
            deletepass: ''
        }

        const onDeleteSubmit = async (values, { resetForm }) => {
            const {thread_id, log_id, route} = item
            let res = {}

            if (mode==="THREAD"){
                res = await deleteThread(route, thread_id)

                if (res.result) {
                    resetForm()
                    onSubmit()
                }
            } else {
                res = await deleteLog(thread_id, log_id)

                if (res.result) {
                    await getLogs(thread_id)
                    resetForm()
                    onSubmit()
                }
            }

            this.setState({
                validationError: res.ErrorMessage
            })
        }

        const renderForm = ({handleSubmit, isSubmitting}) => (
            <FormArea className="text-left" onSubmit={handleSubmit}>
                <p className="text-danger">投稿を削除するには、「削除」または「delete」と入力してください</p>
                <FormInput className="mb-2" name="deletepass" />
                <Button color="primary" type="submit" disabled={isSubmitting}>Submit</Button>{' '}
            </FormArea>
        )

        return <div className={className} style={style}>
            <Formik
                initialValues={initialValues}
                validationSchema={deleteLogValidationSchema}
                onSubmit={onDeleteSubmit}
                render={renderForm}
            />
            <div className="form-error" >{validationError}</div>
        </div>
    }
}

export default DeleteForm

export const DeleteThreadWithModal = (props) => {
    let { children, className, style, title, ...FormAttrs } = props

    title = title || "投稿を削除する"

    return <FormModal className={className} style={style} 
            title={title} 
            Form={DeleteForm}
            FormAttrs={FormAttrs}
        >
        {children}
    </FormModal>
}