export function getNewLogs(logs){
  return {
    type: 'GET_LOGS',
    logs
  }
}

export function addLogs(logs){
  return {
    type: 'ADD_LOGS',
    logs
  }
}

export function changeLog(item){
  return {
    type: 'CHANGE_LOG',
    item
  }
}

export function changeIsLoadingLogs(isLoading){
  return {
    type: 'CHANGE_IS_LOADING_LOGS',
    isLoading
  }
}

export function getNewThreadInfo(thread_info){
  return {
    type: 'ADD_THREAD_INFO',
    thread_info
  }
}


export function changeThreadInfo(item){
  return {
    type: 'CHANGE_THREAD_INFO',
    item
  }
}

export function changeIsLoadingThreadInfo(isLoading){
  return {
    type: 'CHANGE_IS_LOADING_THREAD_INFO',
    isLoading
  }
}

export function getNewThreads(threads){
  return {
    type: 'ADD_THREADS',
    threads
  }
}

export function changeThread(item){
  return {
    type: 'CHANGE_THREAD',
    item
  }
}

export function changeIsLoadingThreads(isLoading){
  return {
    type: 'CHANGE_IS_LOADING_THREADS',
    isLoading
  }
}


export function addUserName(name){
  return {
    type: 'ADD_USER_NAME',
    name
  }
}

export function login(tokens){
  return {
    type: 'LOGIN',
    tokens
  }
}
export function logout(){
  return {
    type: 'LOGOUT'
  }
}

export function refresh(tokens){
  return {
    type: 'REFRESH',
    tokens
  }
}

export function addLogInfo(log_info){
  return {
    type: 'ADD_LOG_INFO',
    log_info
  }
}

export function deleteLogInfo(){
  return {
    type: 'DELETE_LOG_INFO'
  }
}


export function batchAddLikes(likes){
  return {
    type: 'BATCH_ADD_LIKES',
    likes
  }
}

export function batchAddLikesThread(likes){
  return {
    type: 'BATCH_ADD_LIKES_THREAD',
    likes
  }
}